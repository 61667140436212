import product from '@/components/product/index.vue'
import catalogFilter from './components/filter/index.vue'
import selectSort from './components/select-sort/index.vue'
import {mapActions, mapGetters, mapMutations} from "vuex";
import customBreadcrumbs from '@/components/custom-breadcrumbs/index.vue'
import _ from "lodash";
import rangeSlider from "./components/range -filter/index.vue"
import categoryCard from "../category-all/components/category-card/index.vue";
import Vue from 'vue'
import VueHead from 'vue-head'

Vue.use(VueHead)

export default {
  name: "catalog",
  components: {
    product,
    catalogFilter,
    rangeSlider,
    selectSort,
    customBreadcrumbs,

    categoryCard
  },
  data(){
    return {
      selectedFilters: [],
      allProductsArr: [],
      listTrue: false,
      showAll: false,
      params: {
        title: '',
        description: '',
        keywords: '',
        image: '',
        micro: {
          prices: ''
        }
      },
      data:{
        range: {},
        filters: {},
      }
    }
  },
  head: {
    title: function () {
      return {
        inner: this.params.title !== '' ? this.params.title : '',
        separator: ' ',
      }
    },
    meta: function () {
      return [
        {n: 'keywords', content: this.params.keywords, id: 'keywords'},
        {n: 'robots', content: 'noindex, nofollow', id: 'robots'},
        {p: 'og:title', content: this.params.title, id: 'og:title'},
        {p: 'og:description', content: this.params.description, id: 'og:description'},
        {n: 'title', content: this.params.title , id: 'title'},
        {n: 'description', content: this.params.description , id: 'description'},
        {p: 'og:image', content: this.params.image, id: 'og:image'},
      ]
    },
    link: function () {
      let route = this.$route.path
      if (route.slice(1, 4) === 'ru/' || route.slice(1, 4) === 'en/'){
        route = route.slice(3)
      }
      return [
        { rel: 'canonical', href: window.location.origin + this.$route.path, id: 'canonical' },
        {rel: 'alternate', hreflang: 'ru', href: window.location.origin + '/ru' + route, id: 'ru'},
        {rel: 'alternate', hreflang: 'en', href: window.location.origin+ '/en' + route, id: 'en'},
        {rel: 'alternate', hreflang: 'x-default', href: window.location.origin + route, id: 'x-default'},
      ]
    },
    script: function() {
      return [
        {
          t: 'application/ld+json',
          i: this.params.micro.prices
        },
      ]
    }
  },
  computed:{
    ...mapGetters({
      currentCategory: `category/subCategories`,
      allProducts: `category/allProductsList`,
      filterCategories: `category/filterCategories`,
      categoryLoader:`category/categoryLoader`,
      categoryBreadcrumbs:`category/categoryBreadcrumbs`,
      breadcrumbsList:`category/breadcrumbsList`,

      isAuthenticated: `auth/isAuthenticated`,
      favourites: 'favorites/whichList',


    }),
    list(){
      if (this.isAuthenticated) {
        if (!this.currentCategory.products) return []
        if (!this.favourites) return []
        return this.currentCategory.products.map(e => {
          const item = this.favoriteProducts.find(t => t.id === e.id)
          e.selected = !!item
          return e
        })
      } else {
        return this.currentCategory.products
      }
    },
    favoriteProducts(){
      let arr = []
      if(this.isAuthenticated){
        for(let j in this.favourites){
          for(let i in this.favourites[j].products){
            arr.push(this.favourites[j].products[i])
          }
        }
        return arr
      }
    }
  },
  mounted() {
    this.fetchCategory(this.$route.params.secondSlug)
    this.fetchCategoryFilters(this.$route.params.secondSlug).then(()=>{
      this.updateRouter();
    });
  },
  created() {
    // this.allProductsArr = []
    // this.showAll = false
    // setTimeout(() => {
    //   this.getAllProducts()
    //   this.showAll = true;
    // }, 1000)
    // console.log('ddd',this.allProducts)
    // let sort = this.$route.query.sort;
    // if (sort) {
    //   setTimeout(()=>{
    //
    //     let filters = _.cloneDeep(this.$route.query);
    //     delete filters.sort
    //
    //     this.fetchCategoryList({ slug: this.$route.params.secondSlug, sort: sort, data: {filters: [filters]}}, ).then(()=>{
    //       console.log();
    //     });
    //   },500);
    // }
    if(Object.keys(this.$route.query).length) {
      setTimeout(() => {
        let filters = _.cloneDeep(this.$route.query);
        delete filters.sort
        let sort = this.$route.query.sort;
        let keys = Object.keys(filters);
        let values = [];
        let result = {};

        Object.values(filters).forEach((value) => {
          values.push([value])
        })
        keys.forEach((key, i) => result[key] = values[i])

        this.fetchCategoryList({ slug: this.$route.params.secondSlug, sort: sort, data: {filters: result}}, ).then(()=>{
          console.log();
        });
      }, 500)
    }// else {
    //   this.fetchCategory({slug: this.$route.params.slug})
    // }

    // console.log(this.$route.query)
  },
  watch: {
    '$route'(newVal) {
      if(newVal){
        // this.allProductsArr = []
        // this.showAll = false
        // setTimeout(() => {
        //   this.showAll = true;
        //   this.getAllProducts()
        // }, 1000)
        this.fetchCategoryFilters(this.$route.params.secondSlug).then(()=>{
          this.updateRouter();
        })
        if (!Object.keys(this.$route.query).length) {
          this.fetchCategory(this.$route.params.secondSlug)
        }
        this.updateRouter();
      }
    },
    currentCategory: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.setMeta()
        }
      }
    }
  },
  methods: {
    ...mapActions({
      fetchCategory: `category/GET_SUB_CATEGORIES`,
      fetchCategoryFilters: `category/GET_CATEGORY_FILTERS`,
      fetchCategoryList:`category/GET_FILTER_PRODUCTS_LIST`,

      fetchAllCategory: `setting/GET_CATEGORIES_MENU`,
    }),
    ...mapMutations({
      changeActiveCategory:`category/ACTIVE_CATEGORY`
    }),
    setMeta() {
      this.params.title = this.currentCategory.meta_title !== '' ? this.currentCategory.meta_title : 'ICHIBAN STORE',
        this.params.description = this.currentCategory.meta_description !== '' ? this.currentCategory.meta_description : 'ICHIBAN STORE ICHIBAN STORE ICHIBAN STORE ICHIBAN STORE',
        this.params.keywords = this.currentCategory.meta_keywords,
        this.params.image = this.currentCategory.image !== '' ? this.currentCategory.image : 'https://ichibanstore.jp/logo.png'
      this.params.micro.prices = this.currentCategory.micro.prices
      this.$emit('updateHead')
    },
    // getAllProducts() {
    //   let localArr = [];
    //   setTimeout(() => {
    //     if(!this.list.length) {
    //       if(this.currentCategory.subcategories.length) {
    //         this.currentCategory.subcategories.forEach((subCat) => {
    //           if(subCat.products.length) {
    //             subCat.products.forEach((product) => {
    //               localArr.push(product)
    //             })
    //           }
    //           if(!subCat.subcategories) {
    //             localArr = []
    //           }
    //           if(subCat.subcategories.length && !subCat.products.length) {
    //             subCat.subcategories.forEach((nextSubCat) => {
    //               nextSubCat.products.forEach((product) => {
    //                 localArr.push(product)
    //               })
    //             })
    //           }
    //         })
    //         this.allProductsArr = new Set(localArr)
    //       }
    //     } else {
    //       this.allProductsArr = []
    //     }

    //     console.log('doneChild',this.allProductsArr)
    //   }, 500)
    // },
    updateRouter(){
      const query = this.$route.query;
      let currentCategories = [];

      this.filterCategories.forEach(e => {
        if(query.hasOwnProperty(e.slug)){
          currentCategories.push(e);
        }
      });
      let arr = [];
      currentCategories.forEach(e => {
        e.value.forEach(t => {
          for(let i in query){

            let currentQuery = query[i];
            if(!Array.isArray(currentQuery)){
              currentQuery = [];
              currentQuery.push(query[i]);
            }

            currentQuery.forEach(c => {
              if(t.slug === c){
                const elem = _.find(arr, ['key', e.slug]);
                if(elem){
                  elem.categories.push(t);
                }else{
                  arr.push({
                    name: e.name,
                    key: e.slug,
                    categories: [t]
                  });
                }
              }
            });
          }
        });
      });
      this.selectedFilters = arr;
    },
    // sendRequestRange(data){
    //   this.data.range = data
    //   console.log(data)
    //   this.sendRequest()
    // },
    // sendRequestFilters(data){
    //   this.data.filters = data
    //   console.log(data)
    //   this.sendRequest()
    // },
    // sendRequest(){
    //   this.fetchCategoryList({ slug: this.$route.params.slug, data: this.data});
    // }
    // removeItemInFilter(itemSlug, subItemSlug) {
    //   let query = JSON.parse(JSON.stringify(this.$route.query));
    //
    //   _.remove(query[itemSlug], e => e === subItemSlug);
    //
    //   this.$router.replace({
    //     name: 'catalog',
    //     params: { slug: this.$route.params.slug },
    //     query: Object.assign({}, this.$route.query, query)
    //   });
    //
    //   // this.fetchList({ slug: this.$route.params.slug, data: this.$route.query });
    // },
  }
}