import {mapMutations, mapGetters, mapActions} from "vuex";
import _ from "lodash";

export default {
  name: "select-sort",
  data () {
    return {
      open: false,
      select: null,
      sortList: [
        {
          name: this.$t('toDefault'),
          key: 'default'
        },
        {
          name: this.$t('sortByAlphabetical'),
          key: 'alphabeticalUp'
        },
        {
          name: this.$t('sortByAlphabeticalReverse'),
          key: 'alphabeticalDown'
        },
        {
          name: `${this.$t('sortByPrice')} ↑`,
          key: 'priceUp'
        },
        {
          name: `${this.$t('sortByPrice')} ↓`,
          key: 'priceDown'
        },
        // {
        //   name: `${this.$t('sortByPopular')}`,
        //   key: 'popular'
        // }

      ]
    }
  },
  created(){
    if(this.$route.query.sort) {
      const indexOfSelectedFilter = this.sortList.findIndex((element) => element.key === this.$route.query.sort)
      this.select = this.sortList[indexOfSelectedFilter];
    } else {
      this.select = this.sortList[0];
    }
  },
  computed: {},
  methods:{
    ...mapActions({
      fetchCategoryList:`category/GET_FILTER_PRODUCTS_LIST`
    }),
    selectItem(item){

      this.select = item;
      this.open = false;

      this.$router.replace({
        name: this.$route.name,
        params: this.$route.params,
        query: Object.assign({}, this.$route.query, {sort: this.select.key})
      });

      let currentRoute = this.$route.params.secondSlug ? this.$route.params.secondSlug : this.$route.params.slug;
      let filters = _.cloneDeep(this.$route.query);
      delete filters.sort

      this.fetchCategoryList({ slug: currentRoute, sort: this.select.key , data: filters}, ).then(()=>{
        console.log();
      });
    }
  }
}