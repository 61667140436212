import {VueSlideToggle} from "vue-slide-toggle";
import _ from "lodash";
import {mapActions} from "vuex";
export default {
  name: "range-filter",
  data(){
    return{
      minAngle: 0,
      maxAngle: 30,
      range:{
        min:[],
        max:[],
      },
      isOpen:false,
    }
  },
  components:{
    VueSlideToggle
  },
  computed: {
    sliderMin: {
      get: function() {
        let val = parseInt(this.minAngle);
        return val;
      },
      set: function(val) {
        val = parseInt(val);
        if (val > this.maxAngle) {
          this.maxAngle = val;
        }
        this.minAngle = val;
      }
    },
    sliderMax: {
      get: function() {
        let val = parseInt(this.maxAngle);
        return val;
      },
      set: function(val) {
        val = parseInt(val);
        if (val < this.minAngle) {
          this.minAngle = val;
        }
        this.maxAngle = val;
      }
    }
  },
  methods:{
    ...mapActions({
      fetchCategoryList:`category/GET_FILTER_PRODUCTS_LIST`
    }),
    send(){
      this.range.min.push(this.minAngle)
      this.range.max.push(this.maxAngle)
      // console.log(this.range)
      let range = {range:this.range}
      this.$emit('sendRange',range)
      // this.$router.push({
      //   name: 'catalog',
      //   params: {slug:this.$route.params.slug},
      //   query: Object.assign({}, this.$route.query, this.range)
      // });
      this.sendRequest()
    },
    sendRequest: _.debounce(function () {
      let range = {range:this.range}
      // console.log(this.$route.query);
      // this.fetchCategoryList({ slug: this.$route.params.slug, data: range});
    }, 800),
  }

}