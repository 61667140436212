import {VueSlideToggle} from "vue-slide-toggle";
import {mapMutations, mapActions, mapGetters} from "vuex";
import _ from "lodash";

export default {
  props: {
    label: {
      type: String,
      default: ''
    },
    filter: {
      type: Array,
      default: []
    },
    slug: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    }
  },
  name: "catalog-filter",
  data() {
    return {
      isOpen: false,
      checked: [],
      selectedFilters: []
    }
  },
  watch: {
    '$route'() {
      const query = this.$route.query;
      if (query[this.slug]) {
        let currentObj = _.find(this.filterCategories, ['key', this.slug]);
        let selected = [];
        let currentQuery = query[this.slug];
        if (!Array.isArray(currentQuery)) {
          currentQuery = [];
          currentQuery.push(query[this.slug]);
        }
        // console.log(currentObj);
        currentObj.value.forEach(e => {
          currentQuery.forEach(t => {
            if (e.key === t) {
              selected.push(e);
            }
          });
        });
        this.checked = selected;
      } else {
        this.checked = [];
      }
    }
  },
  created() {
    console.log(this.categoryBreadcrumbs);
    if (this.filterCategories) {
      const query = this.$route.query;
      if (query.hasOwnProperty(this.slug)) {
        this.open = true;
        let currentObj = _.find(this.filterCategories, ['key', this.slug]);
        let selected = [];
        let currentQuery = query[this.slug];

        if (!Array.isArray(currentQuery)) {
          currentQuery = [];
          currentQuery.push(query[this.slug]);
        }

        currentObj.value.forEach(e => {
          currentQuery.forEach(t => {
            if (e.key === t) {
              selected.push(e);
            }
          });
        });

        this.checked = selected;
      }
    }
  },
  computed: {
    ...mapGetters({
      filterCategories: `category/filterCategories`,
      currentCategory: `category/subCategories`,
      categoryBreadcrumbs: `category/categoryBreadcrumbs`,
    }),
    query() {
      this.selectedFilters = [];
      if (this.checked) {
        this.checked.forEach(e => {
          // console.log(e.key);
          this.selectedFilters.push(e.key)
        });
      }
      return {
        [this.slug]: this.selectedFilters
      }
    }
  },
  components: {
    VueSlideToggle,
  },
  methods: {
    ...mapMutations({
      setCheckedFilters: `setting/SET_CHECKED_FILTERS`
    }),
    ...mapActions({
      fetchCategory: `currentCategory/GET_SUB_CATEGORIES`,
      fetchCategoryList:`category/GET_FILTER_PRODUCTS_LIST`
    }),
    select() {
      // console.log(this.checked);
      console.log('before',this.currentCategory);
      this.$router.push({
        name: 'catalog',
        params: {secondSlug:this.$route.params.secondSlug},
        query: Object.assign({}, this.$route.query, this.query)
      }).catch(()=>{
        console.log()
      });
      this.sendRequest();
    },
    sendRequest: _.debounce(function () {
      let filters = {filters:this.$route.query}
      this.$emit('sendFilters', {
        filters
      })
      this.fetchCategoryList({ slug: this.$route.params.secondSlug, data: filters}).then(()=>{
        console.log('after',this.currentCategory);
      });

    }, 800),
  }
}